import React, {useEffect, useState} from 'react';
import {
  API_ADD_TO_MANUAL,
  API_FULL_REPLACE_MANUAL,
  API_PARSE_TABLE,
} from '../../../../config';
import useFetch from '../../../../hooks/useFetch';
import styles from './ManualUpload.module.scss';
import {useSelector} from 'react-redux';

const defaultActiveFields: Array<string> = [
  'Industry Group',
  'Company Name',
  'Arabic Symbol',
  'Arabic Symbol Ticker',
  'Arabic Company Name',
  'Arabic Industry Group',
  'Loss Rating',
  'Halal Rating',
];

const ManualUploadComponent = (): JSX.Element => {
  // upload
  // select table
  // check fields
  // add or replace table

  const activeProvider = useSelector((state: any) => state.provider.provider);

  const [
    {isLoading: isLoadingParse, response: responseParse, error: errorParse},
    doFetchParse,
  ] = useFetch(API_PARSE_TABLE + '?provider=' + activeProvider);
  const [
    {isLoading: isLoadingAdd, response: responseAdd, error: errorAdd},
    doFetchAdd,
  ] = useFetch(API_ADD_TO_MANUAL + '?provider=' + activeProvider);
  const [
    {
      isLoading: isLoadingReplace,
      response: responseReplace,
      error: errorReplace,
    },
    doFetchReplace,
  ] = useFetch(API_FULL_REPLACE_MANUAL + '?provider=' + activeProvider);

  // upload | select | check | action
  const [step, setStep] = useState<string>('upload');
  const [tableNames, setTableNames] = useState<any | null>(null);
  const [selectedTable, setSelectedTable] = useState<string>('');
  const [tableContent, setTableContent] = useState<any>(null);
  const [checkedFields, setCheckedFields] = useState<Array<string>>([]);
  const [saveResult, setSaveResult] = useState<string | null>(null);
  const [parsingError, setParsingError] = useState<string | null>(null);

  const parseTable = () => {
    for (let i = 0; i < responseParse?.data?.length; i++) {
      const tbl = responseParse.data[i];
      if (tbl?.name === selectedTable) {
        setTableContent(tbl?.rows);
        break;
      }
    }
    setCheckedFields(defaultActiveFields);
    setStep('check');
  };

  useEffect(() => {
    if (responseAdd || responseReplace) {
      setSaveResult('Success');
      setTimeout(() => {
        setTableNames(null);
        setSelectedTable('');
        setTableContent(null);
        setCheckedFields([]);
        setStep('upload');
        setSaveResult(null);
      }, 10000);
    }
  }, [responseAdd, responseReplace]);

  useEffect(() => {
    if (errorParse || errorAdd || errorReplace) {
      setParsingError('Error');
      setTimeout(() => {
        setTableNames(null);
        setSelectedTable('');
        setTableContent(null);
        setCheckedFields([]);
        setStep('upload');
        setParsingError(null);
      }, 10000);
    }
  }, [errorParse, errorAdd, errorReplace]);

  useEffect(() => {
    if (responseParse) {
      try {
        const tblNames = [];
        for (let i = 0; i < responseParse?.data?.length; i++) {
          const tbl = responseParse.data[i];
          if (tbl?.name) {
            tblNames.push(tbl?.name);
          }
        }
        setTableNames(tblNames);
        setSelectedTable(tblNames[0]);
        setStep('select');
      } catch (error) {
        setParsingError('Parsing error');
        setTimeout(() => {
          setTableNames(null);
          setSelectedTable('');
          setTableContent(null);
          setCheckedFields([]);
          setStep('upload');
          setParsingError(null);
        }, 10000);
      }
    }
  }, [responseParse]);

  return (
    <div className={styles.main}>
      {saveResult && (
        <div>
          <h2>{saveResult}</h2>
        </div>
      )}
      {parsingError && (
        <div>
          <h2>{parsingError}</h2>
        </div>
      )}
      {(isLoadingParse || isLoadingAdd || isLoadingReplace) && (
        <div>
          <h2>wait...</h2>
        </div>
      )}
      {!saveResult &&
        !parsingError &&
        !isLoadingParse &&
        !isLoadingAdd &&
        !isLoadingReplace && (
          <>
            {step === 'upload' && (
              <div className={styles.uploadFile}>
                <div className={styles.overTestBlock}>
                  <div>
                    <div>
                      <h3>File Requirements:</h3>
                    </div>
                    <div>
                      <h4>File type: .xlsx</h4>
                    </div>
                    <div>
                      <h4>Sheet name: any</h4>
                    </div>
                    <div>
                      <h4>Table Structure:</h4>
                      <table>
                        <thead>
                          <tr>
                            <td>Symbol</td>
                            <td>Field name one</td>
                            <td>Field name two</td>
                            <td>Field name three</td>
                            <td>...</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>2030</td>
                            <td>123</td>
                            <td>Text</td>
                            <td>34.56</td>
                            <td>...</td>
                          </tr>
                          <tr>
                            <td>...</td>
                            <td>...</td>
                            <td>...</td>
                            <td>...</td>
                            <td>...</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <label htmlFor="file-upload" className={styles.testBlock}>
                    Upload new xlsx
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    className={styles.hiddenInput}
                    onChange={(event: any) => {
                      const formData = new FormData();
                      formData.append('table', event.target.files[0]);
                      doFetchParse({
                        method: 'POST',
                        headers: {
                          'Content-Type': 'multipart/form-data',
                        },
                        data: formData,
                      });
                    }}
                  />
                </div>
              </div>
            )}
            {step === 'select' && (
              <div>
                <div>
                  <h3>Select a sheet: {selectedTable}</h3>
                </div>
                <div>
                  <select
                    className={styles.selectSize}
                    value={selectedTable}
                    onChange={event => {
                      setSelectedTable(event.target.value);
                    }}>
                    {tableNames.map((name: string, idx: number) => {
                      // if (idx === 0) {
                      //   setSelectedTable(name);
                      // }
                      // setTimeout(() => {
                      //   if (tableNames?.length === 1) {
                      //     setSelectedTable(name);
                      //   }
                      // }, 3000);
                      return (
                        <option key={name} value={name}>
                          {name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {selectedTable && (
                  <div className={styles.btnBlock}>
                    <span onClick={parseTable} className={styles.btnAction}>
                      Parse table
                    </span>
                  </div>
                )}
              </div>
            )}
            {step === 'check' && (
              <div className={styles.overTable}>
                <table>
                  <thead>
                    <tr>
                      {tableContent[0].map((el: any, idx: number) => {
                        return (
                          <th
                            className={
                              el === 'Symbol'
                                ? styles.symbolPoint
                                : checkedFields.indexOf(el) !== -1
                                ? styles.checkedPoint
                                : ''
                            }
                            key={el + '_' + idx}>
                            <>
                              {el === 'Symbol' ? el : ''}
                              {el !== 'Symbol' && (
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={checkedFields.indexOf(el) !== -1}
                                    onChange={() => {
                                      if (checkedFields.indexOf(el) === -1) {
                                        setCheckedFields(prevState => {
                                          return [...prevState, el];
                                        });
                                      } else {
                                        const findIndex =
                                          checkedFields.findIndex(
                                            (x: string) => x === el,
                                          );
                                        const copied = [...checkedFields];
                                        const after = copied.splice(
                                          findIndex + 1,
                                        );
                                        const before = copied.splice(
                                          0,
                                          findIndex,
                                        );
                                        const updated = [...before, ...after];
                                        setCheckedFields(updated);
                                      }
                                    }}
                                  />
                                  {el}
                                </label>
                              )}
                            </>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {tableContent.map((el: any, idx: number) => {
                      if (!idx) {
                        return null;
                      }
                      return (
                        <tr key={'line_' + idx}>
                          {el.map((e: any, id: number) => {
                            return (
                              <td
                                className={
                                  tableContent[0][id] === 'Symbol'
                                    ? styles.symbolPoint
                                    : checkedFields.indexOf(
                                        tableContent[0][id],
                                      ) !== -1
                                    ? styles.checkedPoint
                                    : ''
                                }
                                key={e + '_' + id}>
                                {e}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className={styles.overActions}>
                  <div className={styles.left}>
                    <span
                      className={styles.btnAction}
                      onClick={() => {
                        setTableNames(null);
                        setSelectedTable('');
                        setTableContent(null);
                        setCheckedFields([]);
                        setStep('upload');
                      }}>
                      Cancel
                    </span>
                  </div>
                  <div className={styles.right}>
                    <span
                      className={styles.btnAction}
                      onClick={() => {
                        doFetchAdd({
                          method: 'POST',
                          data: {table: tableContent, fields: checkedFields},
                        });
                      }}>
                      Add/update to existing
                    </span>
                    <span
                      className={styles.btnAction}
                      onClick={() => {
                        doFetchReplace({
                          method: 'POST',
                          data: {table: tableContent, fields: checkedFields},
                        });
                      }}>
                      Clean and save (replace)
                    </span>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
    </div>
  );
};

export default ManualUploadComponent;
