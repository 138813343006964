const twelvedataEarningsEstimate = [
  {
    date: '2024-12-31',
    period: 'next_quarter',
    number_of_analysts: 2,
    avg_estimate: 0.51,
    low_estimate: 0.44,
    high_estimate: 0.57,
    year_ago_eps: 0.46,
  },
  {
    date: '2024-12-31',
    period: 'current_year',
    number_of_analysts: 13,
    avg_estimate: 2.67,
    low_estimate: 2.37,
    high_estimate: 3.45,
    year_ago_eps: 2.67,
  },
  {
    date: '2025-12-31',
    period: 'next_year',
    number_of_analysts: 13,
    avg_estimate: 2.87,
    low_estimate: 2.48,
    high_estimate: 3.76,
    year_ago_eps: 2.67,
  },
];

export default twelvedataEarningsEstimate;
